import Alpine from "alpinejs"
import Swiper from "swiper"
import '~/styles/swiper-bundle.min.css'
import { pauseAllVideos } from '~/utils/video'

export function testimonials(sectionIndex) {
  console.log('testimonials', sectionIndex)
  let testimonialsData = {
    init() {
      this.swiper.init()
      console.log('init testimonials', this.swiper)
    },
    swiper: new Swiper(`.testimonials-swiper-${sectionIndex}`, {
      // Optional parameters
      slidesPerView: 'auto',
      spaceBetween: 16,
      centeredSlides: true,
      breakpoints: {
        // when window width is >= 320px
        768: {
          slidesPerView: 'auto',
          centeredSlides: false,
        },
        1024: {
          slidesPerView: 3,
          centeredSlides: false,
        },
      },
    })
  }

 

  return testimonialsData
}

Alpine.data("testimonials", testimonials)